/* eslint-disable no-underscore-dangle */
import React, { ChangeEvent, useMemo } from 'react';
import { useStore as useReactFlowStore, useZoomPanHelper } from 'react-flow-renderer';
import { LAYERS } from '../../../../constants';
import { useCompressFormContext } from '../../../../context';
import { CompressNode, useNetworkLayerContext } from '../../../../context/NetworkLayerContext';
import { AvailableLayer, Compressed, CompressModel } from '../../../../lib';
import { layersReducer } from '../../../../reducers';
import { getNewLayers } from '../../../../utils';
import { LatencyGuage } from '../LatencyGuage';
import { CellPayload, NetworkInputTableCell } from './components';

// TODO: constants로 추출
const methodToProp = {
  FD_TK: ['In Channel', 'Out Channel', 'In Rank', 'Out Rank'],
  FD_SVD: ['In Channel', 'Out Channel', 'Rank'],
  FD_CP: ['In Channel', 'Out Channel', 'Rank'],
  PR_ID: ['Out Channel', 'Channels'],
  PR_L2: ['Out Channel', 'Ratio'],
  PR_GM: ['Out Channel', 'Ratio'],
  PR_NN: ['Out Channel', 'Ratio'],
};

type NetworkGraphTableProps = {
  model: CompressModel | null;
  compression: Compressed | null;
  layers: AvailableLayer[] | null;
  onClickLayerCheckbox?: React.ChangeEventHandler<HTMLInputElement>;
  onClickAllLayerCheckbox?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

const hasSameDevice = (model: CompressModel) => (layer: AvailableLayer) => {
  const result = layer.latency.find((el) => el.device_name === model.target_device);

  if (result) {
    return result.value;
  }

  return 0;
};

export const NetworkGraphTable: React.FC<NetworkGraphTableProps> = ({
  model,
  compression,
  layers,
  disabled,
  onClickLayerCheckbox,
  onClickAllLayerCheckbox,
}) => {
  const [selectedLayer, setSelectedLayer] = useNetworkLayerContext();
  const reactFlowStore = useReactFlowStore();
  const { setCenter } = useZoomPanHelper();
  const {
    compressForm: { setValue },
  } = useCompressFormContext();

  const allLayerChecked = useMemo(() => {
    if (!layers) {
      return false;
    }

    return layers.every((layer) => layer.use);
  }, [layers]);

  const headers = useMemo(() => {
    if (!compression) {
      return [];
    }

    return methodToProp[compression.compression_method];
  }, [compression]);

  const handleClickLayerCheckbox = (layer: AvailableLayer) => (event: ChangeEvent<HTMLInputElement>) => {
    const { nodes } = reactFlowStore.getState();

    const matchedNode = (nodes as CompressNode[]).find((node) => node.values.name === layer.name);

    if (!matchedNode || (selectedLayer && layer.name === selectedLayer.values.name)) {
      setSelectedLayer(null);

      if (onClickLayerCheckbox) {
        onClickLayerCheckbox(event);
      }

      return;
    }

    const x = matchedNode.__rf.position.x + matchedNode.__rf.width / 2 + 150;
    const y = matchedNode.__rf.position.y + matchedNode.__rf.height / 2;
    const zoom = 1;

    setCenter(x, y, zoom);
    setSelectedLayer(matchedNode);

    if (onClickLayerCheckbox) {
      onClickLayerCheckbox(event);
    }
  };

  const onChangeCell = ({ value, columnName, layerName }: CellPayload) => {
    if (layers && columnName && columnName && layerName) {
      const newLayers = getNewLayers(layers, layerName, columnName, value);

      setValue(LAYERS, newLayers, { shouldValidate: true });
    }
  };

  const maxLatency = useMemo(() => {
    if (compression && model) {
      return compression.available_layers.reduce(layersReducer(model), 0);
    }

    return 0;
  }, [compression, model]);

  if (!layers) {
    return <></>;
  }

  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      <thead className="h-10">
        <tr className="relative">
          <th className="w-10" scope="col">
            <input
              className="absolute top-3 left-3 h-4 w-4"
              type="checkbox"
              onChange={onClickAllLayerCheckbox}
              checked={allLayerChecked}
              disabled={disabled}
            />
          </th>
          <th className="text-xs" scope="col">
            Layer Name
          </th>
          {headers.map((header) => (
            <th key={header} className="text-xs" scope="col">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {layers.map((layer) => (
          <tr key={layer.name}>
            <td className="w-10 text-center">
              <input
                className="h-4 w-4"
                data-layer-name={layer.name}
                type="checkbox"
                onChange={handleClickLayerCheckbox(layer)}
                checked={layer.use}
                disabled={disabled}
              />
            </td>
            <td>
              <div className="m-auto p-2 w-[calc(30vw-192px)]">
                <div className="break-words">{layer.name}</div>
                {model && <LatencyGuage latency={hasSameDevice(model)(layer)} max={maxLatency} />}
                {/* <div className="relative bg-gray-200 rounded">
                  <div className="absolute top-0 left-0 h-full bg-red-300 rounded" />
                  <div className="relative pr-0.5 text-xs text-right">- μs</div>
                </div> */}
              </div>
            </td>
            {headers.includes('In Channel') && <td className="text-center w-14">{layer.channels[0]}</td>}
            {headers.includes('Out Channel') && <td className="text-center w-14">{layer.channels.slice(-1)}</td>}
            {headers.includes('Rank') && (
              <NetworkInputTableCell
                columnName="rank"
                disabled={disabled}
                layerUse={layer.use}
                layerName={layer.name}
                cellValue={layer.values[0]}
                max={Math.min(...layer.channels.slice(0, 2))}
                min={0}
                onChangeCell={onChangeCell}
              />
            )}
            {headers.includes('In Rank') && (
              <NetworkInputTableCell
                columnName="inRank"
                disabled={disabled}
                layerUse={layer.use}
                layerName={layer.name}
                cellValue={layer.values[0]}
                max={layer.channels[0]}
                min={0}
                onChangeCell={onChangeCell}
              />
            )}
            {headers.includes('Out Rank') && (
              <NetworkInputTableCell
                columnName="outRank"
                disabled={disabled}
                layerUse={layer.use}
                layerName={layer.name}
                cellValue={layer.values[1]}
                max={layer.channels[1]}
                min={0}
                onChangeCell={onChangeCell}
              />
            )}
            {headers.includes('Channels') && (
              <NetworkInputTableCell
                columnName="channels"
                disabled={disabled}
                layerUse={layer.use}
                layerName={layer.name}
                cellValue={layer.text || ''}
                max={layer.channels[0]}
                includeMax={false}
                min={0}
                onChangeCell={onChangeCell}
              />
            )}
            {headers.includes('Ratio') && (
              <NetworkInputTableCell
                columnName="ratio"
                disabled={disabled}
                layerUse={layer.use}
                layerName={layer.name}
                cellValue={layer.values[0] || ''}
                max={1}
                min={0}
                onChangeCell={onChangeCell}
              />
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
