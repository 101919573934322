import { PanelHeader } from '@netspresso/components';
import { ADVANCED_COMPRESSION, AUTOMATIC_COMPRESSION } from '@netspresso/shared';

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompressionModeCard } from '../../components/CompressionModeCard/CompressionModeCard';

export const SelectCompression: React.FC = () => {
  const navigate = useNavigate();
  const { modelUid } = useParams();

  const onClickAutomatic = () => {
    navigate(`/compression/new/automatic${modelUid ? `/${modelUid}` : ''}`);
  };

  const onClickAdvanced = () => {
    navigate(`/compression/new/advanced${modelUid ? `/${modelUid}` : ''}`);
  };

  return (
    <div className="w-full flex justify-center">
      <section className="w-[960px] px-3">
        <PanelHeader className="mb-6">Select a Compression Mode</PanelHeader>
        <div className="flex justify-center w-full">
          <CompressionModeCard className="mr-3" method={AUTOMATIC_COMPRESSION} onClick={onClickAutomatic} />
          <CompressionModeCard className="ml-3" method={ADVANCED_COMPRESSION} onClick={onClickAdvanced} />
        </div>
      </section>
    </div>
  );
};
