/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Controls,
  isNode,
  OnLoadFunc,
  PanOnScrollMode,
  useStore as useReactFlowStore,
  useZoomPanHelper,
} from 'react-flow-renderer';
import { CompressNode, useNetworkLayerContext } from '../../../../context/NetworkLayerContext';
import { AvailableLayer, Compressed, CompressModel } from '../../../../lib';
import { layersReducer } from '../../../../reducers';
import { getLayoutedElements } from '../../../../utils/networkLayer';
import { NetworkGraphNode } from '../NetworkGraphNode';
import { NetworkGraphNodeDetail } from '../NetworkGraphNodeDetail';

type NetworkGraphProps = {
  model: CompressModel | null;
  compression: Compressed | null;
  layers: AvailableLayer[] | null;
  disabled?: boolean;
  onClickLayerCheckbox: React.ChangeEventHandler<HTMLInputElement>;
  onClickPane: (event: React.MouseEvent) => void;
};

export const NetworkGraph: React.FC<NetworkGraphProps> = ({
  model,
  compression,
  layers,
  disabled,
  onClickLayerCheckbox,
  onClickPane,
}) => {
  const store = useReactFlowStore();
  const { setCenter } = useZoomPanHelper();
  const [graphInstanceReady, setGraphInstanceReady] = useState(false);
  const [layoutReady, setLayoutReady] = useState(false);

  const [selectedLayer, setSelectedLayer] = useNetworkLayerContext();

  const maxLatency = useMemo(() => {
    if (compression && model) {
      return compression.available_layers.reduce(layersReducer(model), 0);
    }

    return 0;
  }, [compression, model]);

  const [elements, setElements] = useState<CompressNode[]>([]);

  const setLayoutedElements = async (compressModel: CompressModel, compressed: Compressed) => {
    const layoutedElements = await getLayoutedElements(compressModel, compressed.available_layers);

    const customElements = layoutedElements.map((element) => {
      if (isNode(element)) {
        return {
          ...element,
          data: {
            label: (
              <NetworkGraphNode
                key={compressModel.model_id}
                model={compressModel}
                layers={layers}
                element={element}
                onClickLayerCheckbox={onClickLayerCheckbox}
                maxLatency={maxLatency}
                disabled={disabled || false}
              />
            ),
          },
          style: {
            padding: 0,
            border: 'None',
            width: 200,
          },
        };
      }

      return {
        ...element,
        animated: true,
      };
    });

    setElements(customElements);
    setLayoutReady(true);
  };

  useEffect(() => {
    if (compression && model) {
      setLayoutedElements(model, compression);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compression, model, layers]);

  const goFirst = useCallback(() => {
    const { nodes } = store.getState();

    if (nodes.length) {
      const node = nodes[0];
      const x = node.__rf.position.x + node.__rf.width / 2;
      const y = node.__rf.position.y + node.__rf.height / 2;
      const zoom = 1;

      setCenter(x, y, zoom);
    }
  }, [setCenter, store]);

  const handleLoad: OnLoadFunc = useCallback((_reactFlowInstance) => {
    setGraphInstanceReady(true);
  }, []);

  useEffect(() => {
    if (graphInstanceReady && layoutReady) {
      goFirst();
    }
  }, [goFirst, graphInstanceReady, layoutReady]);

  // const handleGoFirstClick = useCallback(() => {
  //   goFirst();
  // }, [goFirst]);

  const handleElementClick = useCallback(() => {}, []);

  const handlePaneClick = useCallback(
    (event: React.MouseEvent) => {
      onClickPane(event);
      setSelectedLayer(null);
    },
    [onClickPane, setSelectedLayer]
  );

  const handleMoveStart = useCallback(() => {
    // setSelectedLayer(null);
  }, []);

  return (
    <div className="w-full h-full">
      <ReactFlow
        elements={elements}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={false}
        zoomOnScroll={false}
        panOnScroll
        paneMoveable
        snapToGrid
        panOnScrollMode={PanOnScrollMode.Free}
        onLoad={handleLoad}
        onElementClick={handleElementClick}
        onPaneClick={handlePaneClick}
        onMoveStart={handleMoveStart}
      >
        <Controls showFitView={false} showInteractive={false}>
          {/* TODO: 첫 레이어로 올라가는 아이콘 및 핸들러 작업 */}
          {/* <ControlButton onClick={handleGoFirstClick}>
            <FirstPageIcon />
          </ControlButton> */}
        </Controls>
        {selectedLayer && <NetworkGraphNodeDetail />}
      </ReactFlow>
    </div>
  );
};
