/* eslint-disable camelcase */
import {
  API_COMPRESS_COMPRESSION_V2,
  API_COMPRESS_MODEL_V2,
  compressApiClient,
  objectToFormData,
} from '@netspresso/shared';
import { Compressed, CompressModel } from '../../lib';
import { RecommendationNodes } from '../../lib/types/compressions/RecommendationNodes';
import { CompressModelType } from '../../schemes';

export const CompressService = (() => {
  const getParentModels = () => {
    return compressApiClient.get<CompressModel[]>(`${API_COMPRESS_MODEL_V2}/parents`);
  };

  const getModel = ({ model_id }: { model_id: string }) => {
    return compressApiClient.get<CompressModel>(`${API_COMPRESS_MODEL_V2}/${model_id}`);
  };

  // const updateModel = ({
  //   model_id,
  //   model_name,
  //   description,
  //   metric_unit,
  //   metric_value,
  //   original_metric_value,
  // }: { model_id: string } & EditCompressedModelType) => {
  //   return compressApiClient.put(`${API_COMPRESS_MODEL_V2}/${model_id}`, {
  //     model_name,
  //     description,
  //     metric_unit,
  //     metric_value,
  //     original_metric_value,
  //   });
  // };

  const autoCompress = async ({ base_model, model_name, description, recommendation_ratio }: CompressModelType) => {
    if (base_model) {
      return compressApiClient.post(`${API_COMPRESS_MODEL_V2}/${base_model.model_id}/auto_compress`, {
        model_name,
        description,
        recommendation_ratio,
      });
    }

    throw Error('base model must be given');
  };

  // FIXME: dependency cycle & 변수명 상수화 진행
  const createCompression = async ({ base_model, model_name, description, compression_method }: CompressModelType) => {
    // dataset 업로드는 따로 return값을 주지않도록 함
    if (base_model) {
      return compressApiClient.post(`${API_COMPRESS_COMPRESSION_V2}`, {
        model_id: base_model.model_id,
        model_name,
        description,
        compression_method,
      });
    }

    throw new Error('Base model is null');
  };

  const uploadCompressDataset = async ({ dataset_file, base_model }: CompressModelType) => {
    if (!base_model) {
      throw new Error('base model must be given');
    }

    if (dataset_file) {
      const payload = {
        file: dataset_file,
      };

      return compressApiClient.post<CompressModel>(
        `${API_COMPRESS_MODEL_V2}/${base_model.model_id}/datasets`,
        objectToFormData(payload)
      );
    }

    throw new Error('Dataset file is not provided');
  };

  const startCompression = async ({ compression_id, layers, policy }: CompressModelType) => {
    return compressApiClient.put(`${API_COMPRESS_COMPRESSION_V2}/${compression_id}`, {
      layers,
      options: {
        policy,
      },
    });
  };

  const getCompression = async ({ compression_id }: { compression_id: string }) => {
    return compressApiClient.get<Compressed>(`${API_COMPRESS_COMPRESSION_V2}/${compression_id}`);
  };

  // 지정한 옵션으로 지정한 모델에 대한 추천값을 얻습니다. VBMF는 [inRank, outRank]의 형태로 결과를 얻으며, global norm 계열은 ratio의 형태로 결과를 얻습니다.
  const createRecommendation = async ({
    base_model,
    compression_id,
    recommendation_method,
    recommendation_ratio,
  }: CompressModelType) => {
    if (base_model) {
      return compressApiClient.post<RecommendationNodes[]>(
        `${API_COMPRESS_MODEL_V2}/${base_model.model_id}/recommendation`,
        {
          compression_id,
          recommendation_method,
          recommendation_ratio,
        }
      );
    }

    throw new Error('Base model is null');
  };

  return {
    autoCompress,
    createCompression,
    uploadCompressDataset,
    startCompression,
    getCompression,
    createRecommendation,
    getParentModels,
    getModel,
    // updateModel,
  };
})();
