import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import { Layout } from './components/DashboardApp';
import { Forbidden, InternalError, Login, NotFound } from './domain';
import { AdvancedCompression, SelectCompression } from './domain/Compression';

import './style.css';

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* <Route index element={<Navigate replace to="models" />} /> */}
          <Route path="compression" element={<Navigate replace to="new" />} />
          <Route path="compression/new" element={<SelectCompression />} />
          <Route path="compression/new/:modelUid" element={<SelectCompression />} />
          <Route path="compression/new/advanced" element={<AdvancedCompression />} />
          <Route path="compression/new/advanced/config" element={<AdvancedCompression />} />
          <Route path="compression/new/advanced/:modelUid/config" element={<AdvancedCompression />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="/not_found" element={<NotFound />} />
        <Route path="/internal_error" element={<InternalError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
